import { useState, useEffect } from 'react';
import UserForm from './UserForm';
import React from 'react';
import { endpoints } from '../config/api';
import { debounce } from '../utils/debounce';  // Import debounce from the new utility file
import { trackSporkClick } from '../config/analytics'; // Import the tracking function

const CraveButton = ({ restaurant, showUserForm = false, onUserFormChange = () => {} }) => {
  const [actualCravingCount, setActualCravingCount] = useState(0);
  const [displayCravingCount, setDisplayCravingCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // Create a deterministic random number based on place_id
  const getRandomIncrementForRestaurant = (placeId) => {
    // Convert place_id to a number using simple hash function
    let hash = 0;
    for (let i = 0; i < placeId.length; i++) {
      hash = ((hash << 5) - hash) + placeId.charCodeAt(i);
      hash = hash & hash; // Convert to 32-bit integer
    }
    // Use the hash to generate a number between 1 and 5
    return Math.abs(hash % 5) + 1;
  };

  // Get consistent random increment for this specific restaurant
  const randomIncrement = getRandomIncrementForRestaurant(restaurant.place_id);

  useEffect(() => {
    setDisplayCravingCount(actualCravingCount + randomIncrement);
  }, [actualCravingCount, randomIncrement]);

  const handleCraveClick = () => {
    console.log('Crave button clicked');  // Add debug logging
    // Add tracking
    trackSporkClick(restaurant.name);
    onUserFormChange(true);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="text-black-600 text-sm font-bold">
          {displayCravingCount} {displayCravingCount === 1 ? 'spork' : 'sporks'}
        </span>
        <button
          onClick={handleCraveClick}
          className="px-4 py-2 border border-white text-white bg-black rounded font-semibold hover:bg-white hover:text-black hover:border-black transition-all duration-200"
          style={{ 
            fontFamily: 'Staatliches, sans-serif',
            fontSize: '20px'
          }}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'SPORK!'}
        </button>
      </div>
      
      {showUserForm && (
        <UserForm 
          restaurant={restaurant}
          onSuccess={(count) => {
            setActualCravingCount(count);
            onUserFormChange(false);
          }}
          onCancel={() => onUserFormChange(false)}
        />
      )}
    </div>
  );
};

export default CraveButton;