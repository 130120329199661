const storage = {
    set: (key, value, ttl) => {
        try {
            const item = {
                value,
                timestamp: Date.now(),
                ttl
            };
            localStorage.setItem(key, JSON.stringify(item));
            console.log(`LocalStorage SET - Key: ${key}, TTL: ${ttl}ms`);
            return true;
        } catch (error) {
            console.error('LocalStorage SET failed:', error);
            return false;
        }
    },

    get: (key) => {
        try {
            const item = localStorage.getItem(key);
            if (!item) {
                console.log(`LocalStorage MISS - Key: ${key}`);
                return null;
            }

            const { value, timestamp, ttl } = JSON.parse(item);
            const age = Date.now() - timestamp;
            const isExpired = age > ttl;

            console.log(`LocalStorage GET - Key: ${key}, Age: ${(age/1000).toFixed(1)}s, Expired: ${isExpired}`);
            
            if (isExpired) {
                localStorage.removeItem(key);
                return null;
            }

            return value;
        } catch (error) {
            console.error('LocalStorage GET failed:', error);
            return null;
        }
    },

    remove: (key) => {
        try {
            localStorage.removeItem(key);
            console.log(`LocalStorage DELETE - Key: ${key}`);
            return true;
        } catch (error) {
            console.error('LocalStorage DELETE failed:', error);
            return false;
        }
    }
};

export default storage; 