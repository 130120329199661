import ReactGA from 'react-ga4';

// Initialize GA4
export const initGA = () => {
  if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
      debug_mode: process.env.NODE_ENV !== 'production'
    });
  } else {
    console.warn('Google Analytics Measurement ID is missing');
  }
};

// Restaurant click tracking
export const trackRestaurantClick = (restaurantName, source = 'map') => {
  ReactGA.event({
    category: 'Restaurant',
    action: 'Click',
    label: restaurantName,
    source: source // 'map' or 'search'
  });
};

// Search result click tracking
export const trackSearchResultClick = (searchTerm, restaurantName) => {
  ReactGA.event({
    category: 'Search',
    action: 'Result Click',
    label: restaurantName,
    searchTerm: searchTerm
  });
};

// Spork button click tracking
export const trackSporkClick = (restaurantName) => {
  ReactGA.event({
    category: 'Spork',
    action: 'Button Click',
    label: restaurantName
  });
};

// Form submission tracking
export const trackFormSubmission = (restaurantName, success = true) => {
  ReactGA.event({
    category: 'Form',
    action: success ? 'Submission Success' : 'Submission Failed',
    label: restaurantName
  });
};

// Match tracking
export const trackMatch = (restaurantName) => {
  ReactGA.event({
    category: 'Match',
    action: 'Created',
    label: restaurantName
  });
};