const getApiConfig = () => {
  const environment = process.env.NODE_ENV || 'development';
  console.log('Current environment:', environment);

  let apiUrl;
  let siteUrl;

  if (environment === 'production') {
    apiUrl = 'https://spork.la/api';
    siteUrl = 'https://spork.la';
  } else {
    apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
    siteUrl = process.env.REACT_APP_SITE_URL || 'http://localhost:3000';
  }

  return {
    environment,
    apiUrl,
    siteUrl,
    reactAppApiUrl: process.env.REACT_APP_API_URL || apiUrl,
  };
};

const config = getApiConfig();
const API_BASE_URL = config.apiUrl;
const SITE_URL = config.siteUrl;

console.log('Current environment:', process.env.NODE_ENV);
console.log('API Base URL:', API_BASE_URL);

export const endpoints = {
    restaurants: {
        base: `${API_BASE_URL}/restaurants`,
        restaurantWeek: `${API_BASE_URL}/restaurants/restaurant-week`,
        cravings: (id) => `${API_BASE_URL}/restaurants/${id}/cravings`,
        getByPlaceId: (placeId) => `${API_BASE_URL}/restaurants?place_id=${placeId}`,
        count: (id) => `${API_BASE_URL}/restaurants/${id}/cravings/count`,
        create: `${API_BASE_URL}/restaurants`
    },
    users: {
        base: `${API_BASE_URL}/users`,
        create: `${API_BASE_URL}/users`
    },
    cravings: {
        base: `${API_BASE_URL}/cravings`,
        count: (id) => `${API_BASE_URL}/cravings/count/${id}`
    }
};

export const fetchRestaurantWeekSpots = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/restaurants/restaurant-week`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching Restaurant Week spots:', error);
    throw error;
  }
};

export default API_BASE_URL;