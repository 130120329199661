import { useState, useEffect } from 'react';
import { endpoints } from '../config/api';
import { getRandomQuestion } from '../constants/questions';
import { trackFormSubmission, trackMatch } from '../config/analytics';

const UserForm = ({ restaurant, onSuccess, onCancel }) => {
  const [formData, setFormData] = useState({
    nickname: '',
    email: '',
    blurb: '',
    socialLink: '',
    question: getRandomQuestion()
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const formStyle = { fontFamily: 'Inter, sans-serif' };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setShowSuccess(false);

    try {
      console.log('Submitting form with restaurant data:', restaurant);
      
      const restaurantData = {
        place_id: restaurant.place_id,
        name: restaurant.name
      };

      const response = await fetch(endpoints.users.create, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nickname: formData.nickname,
          email: formData.email,
          blurb: formData.blurb,
          socialLink: formData.socialLink,
          question: formData.question,
          restaurantData
        })
      });
      
      const data = await response.json();

      if (!response.ok) {
        if (data.code === 'DUPLICATE_CRAVING') {
          throw new Error('You\'ve already sporked this restaurant!');
        }
        throw new Error(data.message || 'Failed to submit');
      }

      // Track successful form submission
      trackFormSubmission(restaurant.name, true);

      // If the response indicates a match was created
      if (data.matchId) {
        trackMatch(restaurant.name);
      }

      console.log('Form submission successful:', data);
      setShowSuccess(true);
      setFormData({ nickname: '', email: '', blurb: '', socialLink: '', question: getRandomQuestion() }); // Reset form
      
      // Show success message for 2 seconds before closing
      await new Promise(resolve => setTimeout(resolve, 3000));
      onSuccess(data.count);
    } catch (err) {
      console.error('Form submission error:', err);
      // Track failed form submission
      trackFormSubmission(restaurant.name, false);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4" style={formStyle}>
      <div className="mb-3">
        <input
          type="text"
          placeholder="Name"
          value={formData.nickname}
          onChange={(e) => setFormData(prev => ({ ...prev, nickname: e.target.value }))}
          style={{ fontFamily: 'Inter, sans-serif' }}
          className="w-full p-2 border border-gray-300 rounded mb-2"
          required
        />
      </div>
      <div className="mb-3">
        <input
          type="email"
          placeholder="Email"
          value={formData.email}
          onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
          style={{ fontFamily: 'Inter, sans-serif' }}
          className="w-full p-2 border border-gray-300 rounded mb-2"
          required
        />
      </div>
      <div className="mb-3">
        <textarea
          placeholder={formData.question}
          value={formData.blurb}
          onChange={(e) => setFormData(prev => ({ ...prev, blurb: e.target.value }))}
          style={{ fontFamily: 'Inter, sans-serif' }}
          className="w-full p-2 border border-gray-300 rounded mb-2"
          required
        />
      </div>
      <div className="mb-3">
        <input
          type="text"
          placeholder="Social media handle (optional)"
          value={formData.socialLink}
          onChange={(e) => setFormData(prev => ({ ...prev, socialLink: e.target.value }))}
          className="w-full p-2 border border-gray-300 rounded mb-2"
        />
      </div>
      {error && <p className="text-red-500 mb-3">{error}</p>}
      {showSuccess && (
        <p className="text-center text-green-600 mb-3" style={{ fontFamily: 'Inter, sans-serif' }}>
          You'll hear from us soon!
        </p>
      )}
      <div className="flex justify-end gap-2 mt-4">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 text-gray-700 rounded hover:bg-gray-100"
          style={{ fontFamily: 'Inter, sans-serif' }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 border border-black text-black bg-white rounded hover:bg-black hover:text-white hover:font-bold transition-all duration-200"
          style={{ fontFamily: 'Inter, sans-serif' }}
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </form>
  );
};

export default UserForm; 