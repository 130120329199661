import Map from './components/Map';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

function App() {
  useEffect(() => {
    // Initialize GA4
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
      // Send initial pageview
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    } else {
      console.warn('Google Analytics Measurement ID is missing');
    }
  }, []);

  return (
    <div className="h-screen w-screen">
      <Map />
    </div>
  );
}

export default App;