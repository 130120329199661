import { useLoadScript, GoogleMap, InfoWindow } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../config/maps";
import { useState, useCallback, useEffect, useRef } from 'react';
import RestaurantPopup from './RestaurantPopup';
import CraveButton from './CraveButton';
import { endpoints } from '../config/api';
import storage from '../utils/storage';
import { X } from 'lucide-react';
import { debounce } from '../utils/debounce';
import { 
  trackRestaurantClick, 
  trackSearchResultClick 
} from '../config/analytics';

const libraries = ["places", "marker"];

const CENTER = {
  lat: 34.0522,
  lng: -118.2437
};

const LA_BOUNDS = {
  north: 34.3373, // Northernmost latitude
  south: 33.7037, // Southernmost latitude
  east: -118.1553, // Easternmost longitude
  west: -118.6682 // Westernmost longitude
};

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
  position: "absolute",
  top: 0,
  left: 0
};

const defaultOptions = {
  scrollwheel: true,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
  disableDefaultUI: false,
  zoom: 11,
  mapId: 'f969ac09fe69c8fe'
};

const colors = ['#ff0000', '#ff8000', '#ffff00', '#00ff00', '#0000ff', '#00ceff', '#8000ff', '#ff6ec9'];

const AdvancedMarker = ({ position, onClick, map, isRestaurantWeek, visible = true }) => {
  const markerRef = useRef(null);

  useEffect(() => {
    if (!map || !window.google || !visible) return;

    // Only create a new marker if one doesn't exist
    if (!markerRef.current) {
      const marker = new window.google.maps.Marker({
        position,
        map,
        icon: isRestaurantWeek ? {
          path: window.google?.maps?.SymbolPath?.CIRCLE || 0,
          fillColor: colors[Math.floor(Math.random() * colors.length)], // Random color selection
          fillOpacity: 1,
          strokeWeight: 0,
          scale: 10,
        } : {
          path: window.google?.maps?.SymbolPath?.CIRCLE || 0,
          fillColor: '#000000', // Updated to black for regular markers
          fillOpacity: 1,
          strokeWeight: 0,
          scale: 14,
        },
        optimized: true
      });

      marker.addListener('click', onClick);
      markerRef.current = marker;
    } else {
      // Just update the existing marker's position if needed
      markerRef.current.setPosition(position);
    }

    return () => {
      // Only remove the marker when the component is truly unmounting
      if (markerRef.current) {
        markerRef.current.setMap(null);
        markerRef.current = null;
      }
    };
  }, [map]); // Only recreate markers when map changes, not on every position/onClick change

  // Update position if it changes without recreating marker
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setPosition(position);
    }
  }, [position]);

  // Update visibility if it changes
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setMap(visible ? map : null);
    }
  }, [visible, map]);

  return null;
};

const CACHE_KEYS = {
    restaurantWeek: 'restaurant_week_spots',
    cravingCounts: (restaurantId) => `craving_count:${restaurantId}`,
};

const CACHE_TTL = {
    restaurantWeek: 1000 * 60 * 60, // 1 hour
    cravingCount: 1000 * 60 * 5,    // 5 minutes
};

// Add this helper function above handlePlacesChanged
const checkRestaurantWeekStatus = async (place) => {
  try {
    console.log('Checking Restaurant Week status for:', place.place_id);
    const response = await fetch(`${endpoints.restaurants.base}?place_id=${place.place_id}`);
    const data = await response.json();
    console.log('Restaurant data from API:', data);
    
    return {
      ...place,
      isRestaurantWeek: data.is_restaurant_week,
      restaurant_week_menu_url: data.restaurant_week_menu_url
    };
  } catch (error) {
    console.error('Error checking restaurant status:', error);
    return { ...place, isRestaurantWeek: false };
  }
};

export default function Map() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
    preventGoogleFontsLoading: true,
    region: "US",
    mapIds: ['f969ac09fe69c8fe']
  });

  // Add ref to track if map has been initialized
  const mapInitialized = useRef(false);

  const [viewport, setViewport] = useState({
    center: CENTER,
    zoom: defaultOptions.zoom
  });

  // Use a regular object instead of Map for state
  const [restaurantsObj, setRestaurantsObj] = useState({});
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const mapRef = useRef(null);
  const [activeUserForm, setActiveUserForm] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  // Add this state to store the offsets
  const [mobileOffset, setMobileOffset] = useState(null);

  const getRestaurantsMap = useCallback(() => {
    return new Map(Object.entries(restaurantsObj));
  }, [restaurantsObj]);

  const fetchRestaurantWeekSpots = useCallback(async () => {
    if (!mapRef.current) return;
    
    try {
        setIsLoading(true);
        
        // Try to get from localStorage first
        const cachedData = storage.get(CACHE_KEYS.restaurantWeek);
        if (cachedData) {
            console.log('Using cached Restaurant Week data from localStorage');
            setRestaurantsObj(prevObj => {
                const newObj = { ...prevObj };
                cachedData.forEach(spot => {
                    if (spot && spot.place_id) {
                        newObj[spot.place_id] = spot;
                    }
                });
                return newObj;
            });
            setIsLoading(false);
            return;
        }

        // If not in localStorage, fetch from API
        const response = await fetch(endpoints.restaurants.restaurantWeek);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        console.log('Restaurant Week data from API:', data);
        
        // Transform the data and ensure coordinates are numbers
        const transformedData = data.map(spot => ({
            name: spot.name,
            geometry: {
                location: {
                    lat: Number(spot.latitude),
                    lng: Number(spot.longitude)
                }
            },
            formatted_address: spot.address,
            isRestaurantWeek: spot.is_restaurant_week,
            special_menu_link: spot.restaurant_week_menu_url,
            place_id: spot.place_id
        }));

        // Cache in localStorage
        storage.set(CACHE_KEYS.restaurantWeek, transformedData, CACHE_TTL.restaurantWeek);
        
        setRestaurantsObj(prevObj => {
            const newObj = { ...prevObj };
            transformedData.forEach(spot => {
                if (spot && spot.place_id && 
                    !isNaN(spot.geometry.location.lat) && 
                    !isNaN(spot.geometry.location.lng)) {
                    newObj[spot.place_id] = spot;
                } else {
                    console.warn('Invalid coordinates for restaurant:', spot);
                }
            });
            return newObj;
        });
    } catch (error) {
        console.error('Error fetching Restaurant Week spots:', error);
    } finally {
        setIsLoading(false);
    }
}, []);

  const isMobile = () => {
    const mobile = window.innerWidth < 768;
    console.log('Mobile check:', {
      windowWidth: window.innerWidth,
      isMobile: mobile
    });
    return mobile;
  };

  const handleMarkerClick = useCallback((restaurant) => {
    if (!restaurant.geometry?.location || !mapRef.current) return;

    // Track the click
    trackRestaurantClick(restaurant.name, 'map');

    console.log('Marker clicked for restaurant:', restaurant);

    // Fetch fresh Restaurant Week status
    checkRestaurantWeekStatus(restaurant).then(enrichedRestaurant => {
      console.log('Fresh restaurant data after marker click:', enrichedRestaurant);
      
      setActiveUserForm(null);

      const position = {
        lat: typeof enrichedRestaurant.geometry.location.lat === 'function' 
          ? enrichedRestaurant.geometry.location.lat() 
          : enrichedRestaurant.geometry.location.lat,
        lng: typeof enrichedRestaurant.geometry.location.lng === 'function' 
          ? enrichedRestaurant.geometry.location.lng() 
          : enrichedRestaurant.geometry.location.lng
      };

      const ZOOM_LEVEL = 13;

      if (isMobile()) {
        const offsetPosition = {
          lat: position.lat - 0.03,
          lng: position.lng + 0.025,
        };
        
        mapRef.current.setZoom(ZOOM_LEVEL);
        mapRef.current.panTo(offsetPosition);
      } else {
        mapRef.current.panTo(position);
        mapRef.current.setZoom(ZOOM_LEVEL);
      }
      
      // Update both state and marker with enriched data
      setRestaurantsObj(prevObj => ({
        ...prevObj,
        [enrichedRestaurant.place_id]: {
          ...enrichedRestaurant,
          position
        }
      }));
      
      setSelectedRestaurant({
        ...enrichedRestaurant,
        position
      });
    });
  }, []);

  const handlePlacesChanged = useCallback(async (searchBox, mapInstance) => {
    const places = searchBox.getPlaces();
    
    // Log the places returned
    console.log('Places returned:', places);
    
    if (!places?.length) return;

    // Update the search value with the selected place name
    setSearchValue(places[0].name);

    const restaurantPlaces = places.filter(place => 
      place.types?.includes('restaurant')
    );
    
    // For each restaurant, check if it's a restaurant week participant
    const restaurantWeekChecks = await Promise.all(
      restaurantPlaces.map(async (place) => {
        try {
          const response = await fetch(`${endpoints.restaurants.base}?place_id=${place.place_id}`);
          console.log('API request URL:', `${endpoints.restaurants.base}?place_id=${place.place_id}`); // Log the API request URL
          const data = await response.json();
          console.log('API response:', data); // Log the API response

          return {
            ...place,
            isRestaurantWeek: data.is_restaurant_week,
            restaurant_week_menu_url: data.restaurant_week_menu_url
          };
        } catch (error) {
          console.error('Error fetching restaurant:', error);
          return { ...place, isRestaurantWeek: false };
        }
      })
    );

    if (restaurantWeekChecks.length > 0) {
      // Update map bounds
      const bounds = new window.google.maps.LatLngBounds();
      restaurantWeekChecks.forEach(place => {
        if (!place.geometry?.location) return;
        
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      mapInstance.fitBounds(bounds);
      
      if (restaurantWeekChecks.length === 1) {
        const searchedRestaurant = restaurantWeekChecks[0];
        
        // Log the transformed restaurant data
        console.log('Transformed restaurant:', {
          ...searchedRestaurant,
          isRestaurantWeek: searchedRestaurant.isRestaurantWeek,
          restaurant_week_menu_url: searchedRestaurant.restaurant_week_menu_url
        });

        trackSearchResultClick(searchValue, searchedRestaurant.name);

        setRestaurantsObj(prevObj => {
          const newObj = { ...prevObj };
          if (!newObj[searchedRestaurant.place_id]) {
            newObj[searchedRestaurant.place_id] = {
              ...searchedRestaurant,
              isRestaurantWeek: searchedRestaurant.isRestaurantWeek,
              restaurant_week_menu_url: searchedRestaurant.restaurant_week_menu_url
            };
          }

          // Log the restaurant being added to state
          console.log('Restaurant being added to state:', newObj[searchedRestaurant.place_id]);

          setTimeout(() => {
            handleMarkerClick(newObj[searchedRestaurant.place_id]);
          }, 100);

          return newObj;
        });
      } else {
        // If multiple results, just update restaurantsObj
        setRestaurantsObj(prevObj => {
          const newObj = { ...prevObj };
          restaurantWeekChecks.forEach(place => {
            if (!newObj[place.place_id]) {
              newObj[place.place_id] = {
                ...place,
                restaurant_week_menu_url: place.restaurant_week_menu_url
              };
            }
          });
          return newObj;
        });
      }
    }
  }, [searchValue, handleMarkerClick]);

  const onLoad = useCallback((mapInstance) => {
    if (!mapInitialized.current && mapInstance) {
      mapRef.current = mapInstance;
      
      // Try more extreme offset values
      const newOffset = {
        marker: new window.google.maps.Size(-150, 0),
        infoWindow: {
          default: new window.google.maps.Size(-150, -40),
          withForm: new window.google.maps.Size(-150, -300) // Much higher offset when form is open
        }
      };
      
      console.log('Setting mobile offset:', newOffset);
      setMobileOffset(newOffset);

      const input = document.getElementById("search-input");
      if (input && mapInstance) {
        console.log('Setting up Autocomplete');
        const autocomplete = new window.google.maps.places.Autocomplete(input, {
          types: ['restaurant'],
          componentRestrictions: { country: 'us' },
          bounds: LA_BOUNDS,
          strictBounds: true,
          fields: ['name', 'geometry', 'formatted_address', 'place_id', 'types', 'vicinity'],
        });
        
        autocomplete.setOptions({ maxResults: 5 });

        mapInstance.addListener("bounds_changed", () => {
          autocomplete.setBounds(mapInstance.getBounds());
        });

        // Handle place selection immediately without debounce
        autocomplete.addListener("place_changed", async () => {
          const place = autocomplete.getPlace();
          if (!place.geometry || !place.geometry.location) {
            console.warn('Place selected with no geometry');
            return;
          }

          // Get fresh Restaurant Week data
          const enrichedPlace = await checkRestaurantWeekStatus(place);
          console.log('Enriched place data:', enrichedPlace);

          const position = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          };

          // Create the final place object with all necessary data
          const finalPlace = {
            ...enrichedPlace,
            position,
            geometry: {
              ...enrichedPlace.geometry,
              location: {
                lat: () => position.lat,
                lng: () => position.lng
              }
            }
          };

          // Update restaurants state with the enriched data
          setRestaurantsObj(prevObj => ({
            ...prevObj,
            [finalPlace.place_id]: finalPlace
          }));

          // Trigger marker click with the enriched data
          handleMarkerClick(finalPlace);
        });
      }

      // Rest of the initialization code...
      setTimeout(() => {
        mapInstance.setCenter(CENTER);
        mapInstance.setZoom(defaultOptions.zoom);
        window.google.maps.event.trigger(mapInstance, 'resize');
        mapInitialized.current = true;
        console.log('Map initialized and resized');
      }, 100);

      fetchRestaurantWeekSpots();
    }
  }, [fetchRestaurantWeekSpots]);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      fetchRestaurantWeekSpots();
    }
  }, [fetchRestaurantWeekSpots]);

  // Add useEffect to monitor map state
  useEffect(() => {
    console.log('Map state:', {
      isLoaded,
      hasMapRef: !!mapRef.current,
      isInitialized: mapInitialized.current
    });
  }, [isLoaded]);

  // Also, let's modify the InfoWindow component to ensure it pans into view when the form opens
  useEffect(() => {
    if (selectedRestaurant && activeUserForm === selectedRestaurant.place_id && mapRef.current) {
      // Pan the map slightly up when the form is open
      const currentCenter = mapRef.current.getCenter();
      const offset = window.innerWidth < 768 ? 0.001 : 0; // Slight offset for mobile
      mapRef.current.panTo({
        lat: currentCenter.lat() + offset,
        lng: currentCenter.lng()
      });
    }
  }, [activeUserForm, selectedRestaurant]);

  // Add a useEffect to ensure Restaurant Week data stays fresh
  useEffect(() => {
    if (selectedRestaurant) {
      checkRestaurantWeekStatus(selectedRestaurant).then(enrichedRestaurant => {
        if (enrichedRestaurant.isRestaurantWeek !== selectedRestaurant.isRestaurantWeek) {
          console.log('Updating Restaurant Week status:', enrichedRestaurant);
          setSelectedRestaurant(enrichedRestaurant);
        }
      });
    }
  }, [selectedRestaurant?.place_id]);

  const handleUserFormSuccess = (count, keepOpen = false) => {
    if (!keepOpen) {
      setActiveUserForm(null);
    }
    // ... other logic
  };

  if (loadError) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-red-500">
          Error loading Google Maps: {loadError.message}
        </div>
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div>Loading maps...</div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-screen">
      <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-10 w-96 relative">
        <input
          id="search-input"
          className="w-full px-4 py-2 rounded-lg shadow-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black focus:border-black pr-10"
          style={{ fontFamily: 'Inter, sans-serif' }}
          type="text"
          placeholder="Search for restaurants in LA..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {searchValue && (
          <button
            onClick={() => {
              setSearchValue('');
              const input = document.getElementById('search-input');
              if (input && mapRef.current) {
                input.value = '';
                
                // Focus the input
                input.focus();
                
                // Remove existing listeners
                window.google.maps.event.clearInstanceListeners(input);
                
                // Reinitialize autocomplete
                const autocomplete = new window.google.maps.places.Autocomplete(input, {
                  types: ['restaurant'],
                  componentRestrictions: { country: 'us' },
                  bounds: LA_BOUNDS,
                  strictBounds: true,
                  fields: ['name', 'geometry', 'formatted_address', 'place_id', 'types', 'vicinity'],
                });
                
                // Reattach the place_changed listener
                autocomplete.addListener("place_changed", () => {
                  const place = autocomplete.getPlace();
                  if (!place.geometry || !place.geometry.location) {
                    console.warn('Place selected with no geometry');
                    return;
                  }

                  const position = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                  };

                  const updateMap = () => {
                    console.log('updateMap called');
                    
                    if (isMobile()) {
                      // Create a bounds object that's offset to position the marker in the upper-left quadrant
                      const bounds = new window.google.maps.LatLngBounds();
                      
                      // Add the actual marker position
                      bounds.extend(new window.google.maps.LatLng(position));
                      
                      // Add a point that's offset to create the desired view
                      bounds.extend(new window.google.maps.LatLng(
                        position.lat - 0.008, // Offset south
                        position.lng + 0.006  // Offset east
                      ));

                      // Fit these bounds
                      mapRef.current.fitBounds(bounds);
                      
                      // Set to same zoom level as restaurant week markers
                      setTimeout(() => {
                        mapRef.current.setZoom(16);
                      }, 100);
                    } else {
                      mapRef.current.panTo(position);
                      mapRef.current.setZoom(15);
                    }

                    const enrichedPlace = {
                      ...place,
                      position,
                      isRestaurantWeek: false
                    };

                    setRestaurantsObj(prevObj => ({
                      ...prevObj,
                      [place.place_id]: enrichedPlace
                    }));

                    setSelectedRestaurant(enrichedPlace);
                    
                    window.google.maps.event.trigger(mapRef.current, 'resize');
                  };

                  requestAnimationFrame(updateMap);
                });
              }
            }}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors"
            aria-label="Clear search"
          >
            <X size={18} />
          </button>
        )}
      </div>
      
      {isLoading && (
        <div className="absolute top-16 left-1/2 transform -translate-x-1/2 z-10">
          <div className="bg-white px-4 py-2 rounded-lg shadow-lg">
            Loading Restaurant Week participants...
          </div>
        </div>
      )}
      
      <GoogleMap
        mapContainerClassName="w-full h-full absolute top-0 left-0"
        center={viewport.center}
        zoom={viewport.zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={() => setSelectedRestaurant(null)}
        options={{
          ...defaultOptions,
          zoom: undefined,
          maxZoom: 20,
          gestureHandling: 'greedy',
          clickableIcons: false,
          disableDoubleClickZoom: true
        }}
      >
        {Object.values(restaurantsObj).map((restaurant) => {
          if (!restaurant?.geometry?.location) {
            console.warn('Restaurant missing geometry:', restaurant); // Debug log
            return null;
          }
          
          const position = {
            lat: typeof restaurant.geometry.location.lat === 'function' 
              ? restaurant.geometry.location.lat() 
              : restaurant.geometry.location.lat,
            lng: typeof restaurant.geometry.location.lng === 'function' 
              ? restaurant.geometry.location.lng() 
              : restaurant.geometry.location.lng
          };
          
          
          return (
            <AdvancedMarker
              key={`marker-${restaurant.place_id}`} // More specific key
              position={position}
              onClick={(e) => {
                e.stop?.();
                handleMarkerClick(restaurant);
              }}
              map={mapRef.current}
              isRestaurantWeek={restaurant.isRestaurantWeek}
              visible={true} // Explicitly set visible
            />
          );
        })}
        
        {selectedRestaurant && (
          <>
            {/* Log before render */}
            {console.log('InfoWindow rendering:', selectedRestaurant)}
            
            <InfoWindow
              position={selectedRestaurant.position}
              onCloseClick={() => {
                setSelectedRestaurant(null);
                setActiveUserForm(null);
              }}
              options={{
                pixelOffset: new window.google.maps.Size(0, -40),
                maxWidth: 300,
                disableAutoPan: true,
                enableEventPropagation: true,
                zIndex: 1
              }}
            >
              <div className="p-3 w-[280px] bg-white rounded-lg shadow border border-black" 
                   style={{ 
                     fontFamily: 'Inter, sans-serif',
                     ...(window.innerWidth < 768 && activeUserForm === selectedRestaurant.place_id && {
                       paddingBottom: '20px'
                     })
                   }}>
                <div className="pr-8">
                  <h2 className="font-bold text-lg mb-2 text-black">
                    {selectedRestaurant.name}
                    {selectedRestaurant.isRestaurantWeek && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-[#f04b4b]">
                        <a
                          href={selectedRestaurant.restaurant_week_menu_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[#f04b4b] hover:text-gray-700 underline"
                        >
                          Restaurant Week Special
                        </a>
                      </span>
                    )}
                  </h2>
                  <p className="text-sm text-black mb-2">
                    {selectedRestaurant.vicinity || selectedRestaurant.formatted_address}
                  </p>
                  <div className="mt-4">
                    <CraveButton 
                      restaurant={selectedRestaurant} 
                      showUserForm={activeUserForm === selectedRestaurant.place_id}
                      onUserFormChange={(show) => {
                        console.log('UserForm change:', show);
                        setActiveUserForm(show ? selectedRestaurant.place_id : null);
                      }}
                    />
                  </div>
                </div>
              </div>
            </InfoWindow>
          </>
        )}
      </GoogleMap>
    </div>
  );
}